import { Link } from 'react-router-dom';

export default function FindMoreDetails(props) {
  return (
    <div className="mt-8 items-center justify-center gap-x-6 mx-auto w-2/3 xl:w-1/2 block cursor-pointer">
      <Link
        to={props.link}
        className="block w-full rounded-md bg-blue-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
      >
        Afla mai multe detalii
      </Link>
    </div>
  );
}
