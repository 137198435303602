import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Home } from './pages/home';
import { Consultanta } from './pages/consultanta';
import { Design } from './pages/design';
import { Gestiune } from './pages/gestiune';
import { Proiectare } from './pages/proiectare';
import { Studii } from './pages/studii';
import { Header } from './headers/header';

export default function App() {
  return (
    <>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/consultanta" element={<Consultanta />} />
        <Route path="/design" element={<Design />} />
        <Route path="/gestiune" element={<Gestiune />} />
        <Route path="/proiectare" element={<Proiectare />} />
        <Route path="/studii" element={<Studii />} />
      </Routes>
    </>
  );
}
