import ContactForm from '../sections/contact-form/contact-form';

export function Gestiune() {
  return (
    <div className="relative isolate px-6 pr-0 xl:pt-10 lg:px-8 lg:pr-0">
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8 pr-0">
          <div className="relative isolate overflow-hidden bg-gray-00 pt-4  shadow-xl shadow-blue-300 sm:rounded-3xl lg:flex lg:gap-x-20 lg:pt-0">
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
              aria-hidden="true"
            >
              <circle
                cx={512}
                cy={512}
                r={512}
                fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                  <stop stopColor="#ffffff" />
                  <stop offset={1} stopColor="#2563EB" />
                </radialGradient>
              </defs>
            </svg>
            <div className="flex flex-wrap gap-x-20 xl:flex-nowrap">
              <div className="mx-auto mt-4 max-w-2xl px-6 md:flex-1 dynamic-margin fade-in-text z-10 lg:text-left">
                <h2 className="text-3xl pb-4 font-bold tracking-tight text-blue-600 sm:text-4xl">
                  Gestiune si realizare proiect
                </h2>
                <div className="text-lg leading-8 text-blue-400">
                  Managerul de proiect are responsabilitatea de a superviza
                  aspectele legate de timp, cost și calitate care sunt esențiale
                  pentru succesul fiecărui proiect. Acesta se implică în
                  planificarea activităților și în administrarea resurselor
                  necesare pentru finalizarea proiectului. Managerul de proiect
                  se asigură de o comunicare eficientă atât cu clientul, cât și
                  cu toate echipele implicate, cum ar fi cele de proiectare,
                  arhitectură și asistență tehnică. Acesta monitorizează și
                  controlează calitatea proiectului prin intermediul
                  mecanismelor de prevenție și control, menține proiectul în
                  cadrul estimării financiare stabilite și intervine pentru a
                  menține structura de planificare a proiectului.
                  <div className="py-4 ">
                    Suntem aici pentru a vă sprijini în:
                  </div>
                  <ul class="list-disc">
                    <li>Emiterea solicitărilor de ofertă</li>
                    <li>Evaluarea și selecționarea potențialilor ofertanți</li>
                    <li>Definirea și supravegherea etapelor de execuție</li>
                    <li>Coordonarea diferitelor specializări și furnizori</li>
                    <li>
                      Validarea plăților după recepția diferitelor faze și
                      stadii fizice
                    </li>
                    <li>
                      Implementarea și gestionarea tehnologiei Building
                      Information Modeling (BIM)
                    </li>
                  </ul>
                </div>
                <div className="mt-10 mb-6 flex items-center justify-center gap-x-6">
                  <a
                    href="#contact"
                    className="rounded-md bg-blue-300 px-3.5 py-2.5 text-sm font-semibold text-blue-900 shadow-sm hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
                  >
                    Afla mai multe detalii
                  </a>
                </div>
              </div>
              <div className="container px-6 pb-6 lg:px-0 lg:pb-0 xl:max-w-6xl mx-auto md:flex xl:flex-1 md:flex-row ">
                <img
                  className="rounded-md bg-white/5 ring-1 ring-white/10 "
                  src="./pexels-pixabay-271667.jpg"
                  alt="App screenshot"
                  width={1824}
                  height={1080}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm></ContactForm>
    </div>
  );
}
