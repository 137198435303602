import ContactForm from '../sections/contact-form/contact-form';
import FirstSection from '../sections/first-section/first-section';
import SecondSection from '../sections/second-section/second-section';
import ServicesSection from '../sections/services-section/services-sections';

export function Home() {
  return (
    <div className="bg-white">
      <FirstSection></FirstSection>
      <SecondSection></SecondSection>
      <ServicesSection></ServicesSection>
      <ContactForm></ContactForm>
    </div>
  );
}
