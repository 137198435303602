import BackgroundBottom from '../../backgounds/background-bottom';
import BackgroundTop from '../../backgounds/background-top';
import ContactUsButton from './contact-us-button';

export default function FirstSection() {
  return (
    <div className="bg-white first-section">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <BackgroundTop></BackgroundTop>
        <div className="flex flex-wrap gap-x-20 xl:flex-nowrap lg:flex-row-reverse mt-9 xl:py-20">
          <div className="container xl:max-w-6xl mx-auto mt-4 mb-8 md:flex xl:flex-1 md:flex-row fade-in-image">
            <img
              className="rounded-md shadow-xl shadow-blue-200"
              src="/buildings.jpeg"
              alt="building"
            ></img>
            {/* <div>Easy Bim</div> */}
          </div>
          <div className="mx-auto mt-4 max-w-2xl md:flex-1 dynamic-margin fade-in-text z-10">
            <div className="text-center xl:mb-12">
              <h1 className="text-4xl font-bold tracking-tight text-blue-600 sm:text-6xl">
                We are building your future!
              </h1>
              <p className="xl:mt-16 mt-6 text-xl leading-8 text-blue-400 font-medium">
                Misiunea noastră la Easy BIM Concept este să aducem inovația și
                excelența în proiectarea asistată de BIM (Building Information
                Modeling) la îndemâna fiecărui client. Ne străduim să oferim
                servicii de proiectare de cea mai înaltă calitate, axate pe
                tehnologia BIM, pentru a transforma conceptele în realitate.
                Suntem dedicați să contribuim la dezvoltarea unor proiecte de
                succes și sustenabile, care să îmbunătățească mediul
                înconjurător și viața oamenilor.
              </p>
            </div>
            <ContactUsButton></ContactUsButton>
          </div>
        </div>

        <BackgroundBottom></BackgroundBottom>
      </div>
    </div>
  );
}
