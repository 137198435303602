import { useEffect } from 'react';
import FindMoreDetails from './find-more-details';
import { fadeInFast } from '../../helper/fade-in-fast';

export const posts = [
  {
    id: 1,
    title: 'Design, Arhitectura si Inginerie',
    href: '/design',
    icon: 'sketch.png',
    description:
      'Oferim servicii integrate de proiectare arhitecturală, inginerie structurală și inginerie de instalatii, bazate pe BIM, utilizând tehnologii de vârf și modelare 3D',
    longDescription: `Indiferent dacă avem de-a face cu proiecte simple sau extrem de complexe, ne asigurăm că oferim clienților avantajele nete ale serviciilor noastre integrate de proiectare arhitecturală, inginerie structurală și inginerie de sisteme.
    Serviciile noastre de arhitectură se bazează pe utilizarea unui software avansat de modelare 3D pentru a crea desene arhitecturale. Aceste desene ne permit să generăm imagini foto-realiste și modele 3D complete ale proiectului. Acest lucru le oferă clienților o previzualizare a proiectelor lor, adăugând astfel valoare serviciilor pe care le punem la dispoziție.
    În faza inițială de proiectare, producem un set detaliat de desene arhitecturale pe care clienții le pot utiliza pentru a planifica și estima costurile proiectului lor de investiții, în strânsă colaborare cu un manager de proiect dedicat.
    Echipa noastră de ingineri efectuează calcule complete și proiectează modele structurale care indică în detaliu ce elemente trebuie implementate pentru a asigura construcția în condiții de siguranță a proiectului.
    
    Suntem capabili să acoperim cu succes fiecare fază de proiectare, începând de la studiul de prefezabilitate până la elaborarea detaliilor tehnice și executarea propriu-zisă:
    
    S.F  -proiect de fezabilitate
    
    D.T.A.C - documentația tehnică pentru obținerea autorizației de construire
    
    D.A.L.I - documentația de avizare a lucrărilor de intervenții
    
    P.T.D.E - proiect tehnic și detalii de execuție 
    
    Suntem capabili să gestionăm cu succes proiecte de proiectare care implică mai multe specializări, cum ar fi instalații sanitare, instalații HVAC și sisteme electrice - atât cu tensiune joasă, cât și cu tensiune foarte joasă.`,
  },
  {
    id: 2,
    title: 'Proiectare întărire și consolidare',
    href: 'proiectare',
    icon: 'layers.png',
    description:
      'Specialiștii noștri tratează cu competență proiecte pentru consolidare, reconfigurare, extindere și adăugare de etaje la structuri existente',
    longDescription: `Proiectarea extinderii și consolidării unei construcții reprezintă o mare responsabilitate pentru specialiștii noștri, deoarece planurile elaborate pentru execuție trebuie să asigure siguranța lucrărilor în curs, evitând impactul asupra altor aspecte ale clădirii și respectând standardele de calitate. În același timp, aceste planuri trebuie să contribuie la eficiența resurselor și reducerea costurilor.
    Evaluarea principală a proiectelor de consolidare implică o analiză tehnică exhaustivă a structurii clădirii existente.
    Echipa noastră de specialiști abordează cu profesionalism proiecte de consolidare, reconfigurare, lărgire sau adăugare de etaje la clădiri deja existente și vine în întâmpinarea clientului cu soluții personalizate.
    Proiectele care vizează consolidarea, modernizarea, extinderea sau supraînălțarea construcțiilor reprezintă inițiative complexe, care se dezvoltă de obicei pe baza recomandărilor rezultate din expertize tehnice și alte analize specializate. Acestea stabilesc cerințele și limitele intervențiilor propuse.
    Proiectele de consolidare necesită un nivel semnificativ de creativitate și trebuie să fie în strânsă corelație cu înțelegerea modului în care se comportă structura existentă, condițiile tehnologice de implementare și impactul intervențiilor pe durata execuției și pe termen lung, în ceea ce privește funcționalitatea și fiabilitatea soluțiilor.`,
  },
  {
    id: 3,
    title: 'Studii de fezabilitate',
    href: '/studii',
    icon: 'knowledge.png',
    description:
      'Studiu de fezabilitate reprezinta o analiza al unui proiect sau al unei investiții din punct de vedere al posibilităților tehnice de realizare și al rentabilității economice',
    longDescription: `Studiul de fezabilitate servește drept unealtă pentru evaluarea și administrarea unei investiții.

    Acesta reprezintă un studiu inițial înaintea începerii unui proiect și constă în principal într-o simulare teoretică a investiției, bazată pe evaluări și cercetări tehnice din diverse domenii, având în vedere toate cheltuielile estimate necesare pentru a efectua o analiză economică detaliată.
    
    Un studiu de fezabilitate cuprinde următoarele componente:
    Evaluarea tehnică pe domenii specializate, care include estimarea cantităților de lucrări și materiale necesare;
    Abordarea aspectelor arhitecturale, structurale, de instalații, amenajare interioară, analiza geotehnică, evaluare tehnică și, în funcție de caz, alte cercetări specializate;
    Analiza economică, care are ca scop determinarea costului total al investiției, identificarea oportunităților, evaluarea eficienței, calculul amortizării și determinarea profitabilității`,
  },
  {
    id: 4,
    title: 'Consultanță și asistență tehnică',
    href: '/consultanta',
    icon: 'brainstorming.png',
    description:
      'Oferim servicii de consultanță pentru a analiza și redefini strategiile, în a îmbunătăți eficiența operațiunilor și a optimiza resursele umane și tehnologice',
    longDescription: `Activitatea de asistență tehnică realizată de către proiectant presupune verificarea modului în care constructorul execută investiția, asigurându-se astfel de respectarea prevederilor din cadrul proiectului tehnic, schimbarea anumitor soluții la solicitarea investitorului și emiterea soluțiilor tehnice de remediere a defectelor apărute în timpul execuției. Aceasta reprezintă ultima etapă din procesul de proiectare

    Principalele atribuții includ:
    Supervizarea șantierului cu verificarea calității executării conform programului fazei determinante.
    Verificarea cantităților de materiale utilizate și corelarea acestora cu liste de materiale corespunzătoare.
    Optimizarea programelor de lucrări`,
  },
  {
    id: 5,
    title: 'Gestiune și realizare proiect',
    href: 'gestiune',
    icon: 'presentation.png',
    description:
      'Managerul de proiect este responsabil de supravegherea factorilor de timp, cost și calitate care stau la baza oricărui proiect.',
    longDescription: `Managerul de proiect are responsabilitatea de a superviza aspectele legate de timp, cost și calitate care sunt esențiale pentru succesul fiecărui proiect. Acesta se implică în planificarea activităților și în administrarea resurselor necesare pentru finalizarea proiectului. Managerul de proiect se asigură de o comunicare eficientă atât cu clientul, cât și cu toate echipele implicate, cum ar fi cele de proiectare, arhitectură și asistență tehnică. Acesta monitorizează și controlează calitatea proiectului prin intermediul mecanismelor de prevenție și control, menține proiectul în cadrul estimării financiare stabilite și intervine pentru a menține structura de planificare a proiectului

    Suntem aici pentru a vă sprijini în:
    
    Emiterea solicitărilor de ofertă
    Evaluarea și selecționarea potențialilor ofertanți
    Definirea și supravegherea etapelor de execuție
    Coordonarea diferitelor specializări și furnizori
    Validarea plăților după recepția diferitelor faze și stadii fizice
    Implementarea și gestionarea tehnologiei Building Information Modeling (BIM)`,
  },
];

export default function ServicesSection() {
  useEffect(() => {
    const targetElement = document.querySelector('.servicii-descrise');
    const text_servicii = document.querySelector('.text-servicii');
    fadeInFast(targetElement);
    fadeInFast(text_servicii);
  }, []);

  return (
    <div className="bg-white pt-12 sm:pt-16 pb-2 sm:pb-4" id="servicii">
      <div className="mx-auto max-w-screen-2xl px-6 lg:px-8">
        <div className=" w-full text-center lg:mx-0 text-servicii">
          <h2 className="text-4xl font-bold tracking-tight text-blue-600 sm:text-6xl">
            Servicii
          </h2>
          <p className="mt-4 xl:mt-6 text-2xl font-semibold leading-8 text-blue-400">
            Hai sa afli cum te putem ajuta!
          </p>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 xl:gap-y-16 gay-y-4 pt-4 sm:mt-8 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3 servicii-descrise">
          {posts.map((post) => (
            <div
              key={post.id}
              className="flex max-w-xl mx-auto xl:pr-3 flex-col items-start justify-between mb-16 xl:mr-8"
            >
              <div className="mb-10 mx-auto w-1/2 block">
                <img
                  className="mr-auto ml-auto block"
                  src={post.icon}
                  alt="icon"
                ></img>
              </div>
              <div className="group relative">
                <h3 className="mt-3 mr-3 text-2xl font-bold text-center leading-6 text-blue-900 group-hover:text-blue-600">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
                <p className="mt-5 line-clamp-5 text-base text-center leading-6 text-blue-400">
                  {post.description}
                </p>
              </div>
              <FindMoreDetails link={post.href}></FindMoreDetails>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
