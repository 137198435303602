export default function ContactUsButton() {
  return (
    <div className="mt-10 flex items-center justify-center gap-x-6 mb-10 z-10">
      <a
        href="#contact"
        className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
      >
        Contacteaza-ne
      </a>
    </div>
  );
}
