export function fadeInFast(target) {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('fade-in-image-fast');
      }
    });
  });

  observer.observe(target);
}
