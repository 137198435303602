import ContactForm from '../sections/contact-form/contact-form';

export function Design() {
  return (
    <div className="relative isolate px-6 pr-0 xl:pt-10 lg:px-8 lg:pr-0">
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8 pr-0">
          <div className="relative isolate overflow-hidden bg-gray-00 pt-4  shadow-xl shadow-blue-300 sm:rounded-3xl lg:flex lg:gap-x-20 lg:pt-0">
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
              aria-hidden="true"
            >
              <circle
                cx={512}
                cy={512}
                r={512}
                fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                  <stop stopColor="#ffffff" />
                  <stop offset={1} stopColor="#2563EB" />
                </radialGradient>
              </defs>
            </svg>
            <div className="flex flex-wrap gap-x-20 xl:flex-nowrap">
              <div className="mx-auto mt-4 max-w-2xl px-6 md:flex-1 dynamic-margin fade-in-text z-10 lg:text-left">
                <h2 className="text-3xl pb-4 font-bold tracking-tight text-blue-600 sm:text-4xl">
                  Design, Arhitectura si Inginerie
                </h2>
                <div className="text-lg leading-8 text-blue-400">
                  Indiferent dacă avem de-a face cu proiecte simple sau extrem
                  de complexe, ne asigurăm că oferim clienților avantajele nete
                  ale serviciilor noastre integrate de proiectare arhitecturală,
                  inginerie structurală și inginerie de sisteme. Serviciile
                  noastre de arhitectură se bazează pe utilizarea unui software
                  avansat de modelare 3D pentru a crea desene arhitecturale.
                  Aceste desene ne permit să generăm imagini foto-realiste și
                  modele 3D complete ale proiectului. Acest lucru le oferă
                  clienților o previzualizare a proiectelor lor, adăugând astfel
                  valoare serviciilor pe care le punem la dispoziție. În faza
                  inițială de proiectare, producem un set detaliat de desene
                  arhitecturale pe care clienții le pot utiliza pentru a
                  planifica și estima costurile proiectului lor de investiții,
                  în strânsă colaborare cu un manager de proiect dedicat. Echipa
                  noastră de ingineri efectuează calcule complete și proiectează
                  modele structurale care indică în detaliu ce elemente trebuie
                  implementate pentru a asigura construcția în condiții de
                  siguranță a proiectului. Suntem capabili să acoperim cu succes
                  fiecare fază de proiectare, începând de la studiul de
                  prefezabilitate până la elaborarea detaliilor tehnice și
                  executarea propriu-zisă:
                  <div className="py-4 ">Principalele atribuții includ:</div>
                  <ul class="list-disc">
                    <li>S.F -proiect de fezabilitate</li>
                    <li>
                      D.T.A.C - documentația tehnică pentru obținerea
                      autorizației de construire
                    </li>
                    <li>
                      D.A.L.I - documentația de avizare a lucrărilor de
                      intervenții
                    </li>
                    <li>P.T.D.E - proiect tehnic și detalii de execuție</li>
                    <li>
                      Suntem capabili să gestionăm cu succes proiecte de
                      proiectare care implică mai multe specializări, cum ar fi
                      instalații sanitare, instalații HVAC și sisteme electrice
                      - atât cu tensiune joasă, cât și cu tensiune foarte joasă.
                    </li>
                  </ul>
                </div>
                <div className="mt-10 mb-6 flex items-center justify-center gap-x-6">
                  <a
                    href="#contact"
                    className="rounded-md bg-blue-300 px-3.5 py-2.5 text-sm font-semibold text-blue-900 shadow-sm hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
                  >
                    Afla mai multe detalii
                  </a>
                </div>
              </div>
              <div className="container px-6 pb-6 lg:px-0 lg:pb-0 xl:max-w-6xl mx-auto md:flex xl:flex-1 md:flex-row ">
                <img
                  className="rounded-md bg-white/5 ring-1 ring-white/10 "
                  src="./pexels-thirdman-5582867.jpg"
                  alt="App screenshot"
                  width={1824}
                  height={1080}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm></ContactForm>
    </div>
  );
}
