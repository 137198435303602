import { useEffect } from 'react';
import BackgroundBottom from '../../backgounds/background-bottom';
import BackgroundTop from '../../backgounds/background-top';
import { fadeInFast } from '../../helper/fade-in-fast';

export default function SecondSection() {
  useEffect(() => {
    const targetElement = document.querySelector('.fade-in-image-target');
    const textTargetElement = document.querySelector('.fade-in-text-target');

    fadeInFast(targetElement);
    fadeInFast(textTargetElement);
  }, []);

  return (
    <div className="bg-white second-section">
      <div className="relative isolate px-6 lg:px-8">
        <BackgroundTop></BackgroundTop>
        <div className="flex flex-wrap gap-x-20 xl:flex-nowrap lg:flex xl:pt-20">
          <div className="container xl:max-w-6xl mx-auto mt-4 mb-8 md:flex xl:flex-1 md:flex-row fade-in-image-target">
            <img
              className="rounded-md shadow-xl shadow-blue-200"
              src="/green-buildings.jpeg"
              alt="buildings2"
            ></img>
            {/* <div>Easy Bim</div> */}
          </div>
          <div className="mx-auto mt-6 max-w-2xl md:flex-1 dynamic-margin fade-in-text-target">
            <div className="text-center">
              <h1 className="text-4xl xl:mt-4 font-bold tracking-tight text-blue-600 sm:text-6xl">
                Viziunea noastră
              </h1>
              <p className="mt-6 xl:mt-16 text-xl leading-8 text-blue-400">
                Viziunea noastră este să devenim lideri în industria proiectării
                asistate de BIM, să revoluționăm modul în care se concep, se
                dezvoltă și se gestionează proiectele de construcții. Dorim să
                fim recunoscuți drept partenerul de încredere al clienților
                noștri în transformarea ideilor lor în clădiri și structuri
                remarcabile. Ne propunem să ne extindem influența la nivel
                global și să facilităm dezvoltarea comunităților printr-un
                angajament continuu pentru inovație, sustenabilitate și calitate
              </p>
            </div>
          </div>
        </div>
        <BackgroundBottom></BackgroundBottom>
      </div>
    </div>
  );
}
